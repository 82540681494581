import { useAppKitProvider, useAppKitAccount } from '@reown/appkit/vue'
import { type SIWESession, type SIWEVerifyMessageArgs, type SIWECreateMessageArgs, createSIWEConfig, formatMessage } from '@reown/appkit-siwe'
import axios from 'axios'
import { BrowserProvider, getAddress } from 'ethers'
import { SiweMessage } from 'siwe'

import { ACCESS_TOKEN } from '~/constants/wallet'
import UserInfo from '~/store/user'
import { setStorage, getCookieData, deleteCookieData } from '~/utils/storage'
const BASE_URL = useRuntimeConfig().public.apiBase + '/siwe'
let sessionKey = ''
const localAccessTokenKey = ACCESS_TOKEN

const initSession = async () => {
  const res = await axios.get(BASE_URL + '/init-session', {
    headers: {
      'Content-Type': 'application/json',
      'SIWE-Session-Key': localStorage.getItem('siwe_session_key') || ''
    }
  })

  if (res?.status !== 200) {
    if (res?.status === 401) {
      localStorage.removeItem('siwe_session_key')
      deleteCookieData(localAccessTokenKey)
    } else {
      throw new Error('Network response was not ok')
    }
  }

  const data = res.data
  if (data.session_key) {
    // localStorage.setItem('siwe_session_key', data.session_key)
    sessionKey = data.session_key
  }
}

/* Function that returns the user's session - this should come from your SIWE backend */
async function getSession() {
  if (!localStorage.getItem('siwe_session_key')) {
    await initSession()
  }

  const res = await axios.get(BASE_URL + '/session', {
    headers: {
      'Content-Type': 'application/json',
      'SIWE-Session-Key': sessionKey || ''
    }
  })

  if (res?.status !== 200) {
    if (res?.status === 401) {
      localStorage.removeItem('siwe_session_key')
      deleteCookieData(localAccessTokenKey)
    } else {
      throw new Error('Network response was not ok')
    }
  }

  if (getCookieData(localAccessTokenKey)) {
    // axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem(localAccessTokenKey)
    const userInfoRes = await axios.get(BASE_URL + '/user-info', {
      headers: {
        'Content-Type': 'application/json',
        'SIWE-Session-Key': sessionKey || '',
        Authorization: 'Bearer ' + getCookieData(localAccessTokenKey)
      }
    })
    const userInfoData = userInfoRes.data
    if (userInfoData.data) {
      UserInfo().setUserInfo(userInfoData.data.user)
    }
  }

  const data = await res.data

  return data == '{}' ? null : (data as SIWESession)
}

const getNonce = async (): Promise<string> => {
  const res = await axios.get(BASE_URL + '/nonce', {
    headers: {
      'SIWE-Session-Key': sessionKey || ''
    }
  })

  if (res?.status !== 200) {
    if (res?.status === 401) {
      localStorage.removeItem('siwe_session_key')
      deleteCookieData(localAccessTokenKey)
    } else {
      throw new Error('Network response was not ok')
    }
  }
  const data = res.data

  return data.nonce
}

/* Use your SIWE server to verify if the message and the signature are valid */
const verifyMessage = async ({ message, signature }: SIWEVerifyMessageArgs) => {
  try {
    const router = useRouter()
    const response = await axios.post(
      BASE_URL + '/login',
      {
        message,
        signature,
        ref_id: router.currentRoute.value.query.startapp
      },
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'SIWE-Session-Key': sessionKey || ''
        }
      }
    )

    if (response?.data?.status_code !== 200) {
      if (response?.data?.message) {
        ElMessage({
          message: response?.data?.message,
          type: 'error',
          grouping: true
        })
      }
      return false
    }

    // const { isFirtRouterHome } = storeToRefs(UserInfo())
    const result = response.data
    setCookieData(localAccessTokenKey, result.data.access_token)
    UserInfo().setUserInfo(result.data.user)
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + result.data.access_token

    return true
  } catch (error) {
    return false
  }
}

export const logout = async () => {
  const res = await axios.get(BASE_URL + '/logout', {
    headers: {
      'Content-Type': 'application/json',
      'SIWE-Session-Key': sessionKey || '',
      Authorization: 'Bearer ' + getCookieData(localAccessTokenKey)
    }
  })

  if (res?.data?.status_code === 200 || res?.data?.status_code === 401) {
    localStorage.removeItem('siwe_session_key')
    deleteCookieData(localAccessTokenKey)
  }
}

export const subscribeEventsCallback = async (newState: any) => {
  const { address, isConnected } = useAppKitAccount()
  if (newState.data.event === 'CONNECT_SUCCESS') {
    await login()
  }
}
export const login = async () => {
  const { address, isConnected } = useAppKitAccount()
  if (!isConnected) {
    throw new Error('User disconnected')
  }

  const { walletProvider } = useAppKitProvider('eip155')
  const ethersProvider = new BrowserProvider(walletProvider as any)
  const signer = await ethersProvider.getSigner()
  const session = await getSession()

  const nonce = await getNonce()
  const params = {
    chainId: 56,
    domain: window.location.host,
    nonce,
    uri: window.location.origin,
    address: signer.address,
    version: '1',
    iat: new Date().toISOString()
  } as any
  const siweMessage = new SiweMessage(params)
  const message = siweMessage.toMessage()

  try {
    const signature = await signer.signMessage(message)

    const result = await verifyMessage({ message, signature })

    if (result) {
      const router = useRouter()
      ElMessage({
        message: 'Login successful',
        type: 'success',
        grouping: true
      })
      router.push('/home')
    }
  } catch (error: any) {
    UserInfo().disconnect()
    localStorage.removeItem('siwe_session_key')
    localStorage.removeItem(localAccessTokenKey)
    if (error.code === 4001 || error.message.includes('user rejected action')) {
      console.error('User rejected the signature request')
    } else {
      console.error('Error during the signing process:', error)
    }
  }
}

// Check the full example for signOut and getNonce functions ...
/* Create a SIWE configuration object */ // This code snippet may change for reown - to be confirmed
export const siweConfig = createSIWEConfig({
  // eslint-disable-next-line require-await
  getMessageParams: async () => ({
    domain: window.location.host,
    uri: window.location.origin,
    chains: [1, 56, 2020],
    statement: 'Please sign with your account'
  }),
  createMessage: ({ address, ...args }: SIWECreateMessageArgs) => {
    const message = formatMessage(args, address)
    const regex = /0x[a-fA-F0-9]{40}/
    const matches = address.match(regex)
    if (!matches) {
      return message
    }

    const ethereumAddress = matches[0]
    const encodeAddress = getAddress(ethereumAddress + '')

    return message.replace(ethereumAddress, encodeAddress)
  },
  getNonce: async () => {
    // This is only an example, substitute it with your actual nonce getter.
    const nonce = await getNonce()
    if (!nonce) {
      throw new Error('Failed to get nonce!')
    }

    return nonce
  },
  getSession,
  verifyMessage,
  // eslint-disable-next-line require-await
  onSignIn: async () => {
    const router = useRouter()
    router.push('/home')
  },
  signOut: async () => {
    await logout()
    const router = useRouter()
    router.push('/')
    return true
  }
})

export const isMetamaskInstalled = () => {
  if (typeof window === 'undefined') {
    return false
  }

  // @ts-ignore
  if (window.ethereum?.isMetaMask) {
    return true
  }

  // @ts-ignore
  if (window.ethereum?.providers?.some((p) => p.isMetaMask)) {
    return true
  }

  return false
}

export const WALLET_METAMASK = {
  id: 'metamask',
  title: 'Metamask',
  get installed() {
    return isMetamaskInstalled()
  },
  deepLink: 'https://metamask.app.link/dapp/' + window.location.href.replace('https://', ''),
  downloadLink: 'https://metamask.app.link/dapp/' + window.location.href.replace('https://', ''),
  support: true
}

export default { siweConfig }
